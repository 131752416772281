<template>
  <el-input
      :placeholder="`${$t('system.enter_text')}...`"
      :size="size"
      v-model="filter.value"
  />
</template>

<script>
import filtersGeneratorMixin from '../mixin/filtersGeneratorMixin';

export default {
  name: 'fieldInput',
  mixins: [filtersGeneratorMixin],
  watch: {
    'filter.value'(newVal, oldVal) {
      if (!/^[0-9., +\-\\)\\(]*$/.test(newVal)) {
        this.filter.value = oldVal;
      }
    },
  },
};
</script>

<style scoped>

</style>
